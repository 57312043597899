<template>
  <a-modal :visible.sync="show"
           title="确定要取消订单吗?"
           destroyOnClose
           width="500px"
           okText="确定"
           @ok="handleOk"
           @cancel="$emit('update:show', false)">

    <base-table v-if="payData.length"
        class="mt-2 mb-2"
        :customHeight="300" 
        :columnsData="columns"
        :tableData="payData">
      <template #operation="{ record }">
          <a-checkbox v-if="record.needManual" v-model="record.checked">已人工退费</a-checkbox>
          <span v-else>订单取消后，系统自动退费</span>
      </template>
    </base-table>
  </a-modal>
</template>

<script>
export default {
  props:{
    show: {
      type: Boolean,
      default: false,
    },
    payInfo: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      checked: false,

      pay_type: [
        {
          title: "微信",
          key: "wx_pay_amount",
        },
        {
          title: "钱包",
          key: "vip_card_pay_amount",
        },
        {
          title: "实体卡",
          key: "stored_value_card_pay_amount",
        },
        {
          title: "支付宝",
          key: "alipay_amount",
        },
        {
          title: "货到付款",
          key: "cash_delivery_amount",
          needManual: true, // 其实货到付款不涉及（因为取消订单时、肯定没有收货到付款的钱）
          checked: false
        },
        {
          title: "其他支付",
          key: "other_pay_amount",
          needManual: true,
          checked: false
        },
        {
          title: "挂账",
          key: "on_account_amount",
          needManual: true,
          checked: false
        },
      ],

      columns: [
        {
          title: "支付方式",
          dataIndex: "title",
          align: "center",
          width: 130,
        },
        {
          title: "金额",
          dataIndex: "amount",
          align: "center",
          width: 90,
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          slots: { customRender: "operation" },
        },
      ],

      payData: [],

    }
  },
  mounted(){
    // debug
    // this.payInfo["alipay_amount"] = "0.4"
    // this.payInfo["on_account_amount"] = "0.9"
    // this.payInfo["cash_delivery_amount"] = "0.00"

    this.payData = this.pay_type.map(el=>{
      el.amount = Number(this.payInfo[el.key])
      return el
    }).filter(el=>el.amount > 0)
  },

  methods:{
    handleOk(){
      if(this.payData.findIndex(el=>{
        return el.needManual && !el.checked
      }) > -1){
        this.$message.warning("请确保已手动退费")
        return
      }

      let params = {}
      this.payData.map(el=>{
        if(el.needManual){
          params[el.key] = el.amount
        }
      })

      this.$emit('ok', params)
    }
  }
}
</script>

<style>
</style>